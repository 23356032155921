@import url(https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  background-color: #25282a;
  min-height: 100vh;
  font-size: 18px; }

h1 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 2rem;
  margin: 0; }
  @media all and (min-width: 991px) {
    h1 {
      font-size: 2.5rem; } }

h2 {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 300; }

p {
  margin: 0; }

a {
  color: #009490;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  text-decoration: none; }
  a:hover, a:focus {
    color: #76232f;
    -webkit-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    text-decoration: none; }

svg,
.svg {
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  width: 32px;
  height: 32px;
  fill: #fff; }

.app {
  min-height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Barlow Condensed", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.1;
  color: #fff; }
  .app * {
    outline: 0 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .app .app-header {
    padding-top: 10vh;
    width: 100%; }
    .app .app-header #app-header-image-row {
      margin-bottom: 1vh; }
      .app .app-header #app-header-image-row #app-header-image {
        padding-right: 15vw; }
    .app .app-header #scroll-down-svg {
      max-width: 40px;
      max-height: 40px; }
  .app .app-main {
    width: 100%;
    padding: 5vh 0;
    background-color: #76232f;
    margin: 5vh 0; }
    .app .app-main .features {
      list-style-type: none;
      padding: 0;
      margin: 0; }
      .app .app-main .features li {
        padding: 5px 0;
        margin: 0; }
  .app .app-footer {
    width: 100%;
    padding-bottom: 10vh; }
    .app .app-footer .section-title {
      margin-bottom: 30px; }
    .app .app-footer .ctct-inline-form .ctct-form-defaults {
      background-color: transparent;
      padding: 0 0 30px 0; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-error,
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-errorMessage {
        background-color: #ffDDDD;
        color: #76232f;
        padding: 10px;
        margin: 10px 0;
        text-align: center; }
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-error P,
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-errorMessage P {
          margin: 0;
          text-align: center; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-header {
        margin: 0;
        font-size: 1.5rem;
        font-weight: 300;
        color: #fff;
        font-family: "Barlow Condensed", sans-serif;
        line-height: 1.1;
        margin-bottom: 15px; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-field {
        margin: 0; }
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-field .ctct-form-label {
          color: #b2b5b6;
          font-family: "Barlow Condensed", sans-serif;
          margin: 0 0 5px 0;
          text-transform: uppercase;
          font-size: 1.25rem;
          font-weight: 300; }
          .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-field .ctct-form-label:before {
            color: #76232f; }
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-field .ctct-form-element {
          border-radius: 0;
          font-weight: 300;
          font-size: 1.1rem;
          color: #25282a;
          font-family: "Barlow Condensed", sans-serif;
          text-align: center;
          margin-bottom: 10px;
          border: 0; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-gdpr-text {
        color: #b2b5b6; }
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-gdpr-text A {
          color: #009490; }
          .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-gdpr-text A:hover, .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-gdpr-text A:focus {
            color: #fff; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-button {
        border-radius: 3px;
        font-family: "Barlow Condensed", sans-serif;
        display: inline-block;
        width: auto;
        border: 0;
        border-radius: 3px;
        font-size: 1.2rem;
        padding: 7px 50px;
        text-transform: uppercase;
        font-weight: 500;
        background-color: #009490;
        -webkit-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out; }
        .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-button:hover, .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-custom .ctct-form-button:focus {
          background-color: #76232f;
          -webkit-transition: all 250ms ease-in-out;
          transition: all 250ms ease-in-out; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-footer {
        padding: 0; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-success .ctct-form-header {
        margin: 0;
        font-size: 2rem;
        font-weight: 300;
        color: #fff;
        font-family: "Barlow Condensed", sans-serif;
        line-height: 1.1;
        margin-bottom: 15px; }
      .app .app-footer .ctct-inline-form .ctct-form-defaults .ctct-form-success .ctct-form-text {
        color: #fff;
        font-family: "Barlow Condensed", sans-serif; }
    .app .app-footer #signup-form {
      max-width: 500px;
      margin: auto;
      margin-bottom: 20px;
      display: block; }
      .app .app-footer #signup-form .form-group {
        margin: 0; }
      .app .app-footer #signup-form button {
        display: block;
        width: 100%;
        border: 0;
        border-radius: 3px;
        font-size: 1.2rem;
        padding: 3px 20px;
        text-transform: uppercase;
        font-weight: 500;
        background-color: #009490;
        -webkit-transition: all 250ms ease-in-out;
        transition: all 250ms ease-in-out; }
        .app .app-footer #signup-form button:hover, .app .app-footer #signup-form button:focus {
          background-color: #76232f;
          -webkit-transition: all 250ms ease-in-out;
          transition: all 250ms ease-in-out; }
  .app .signup-form-panel {
    position: relative; }
    .app .signup-form-panel .loading-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1; }
      .app .signup-form-panel .loading-overlay .spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center; }
      .app .signup-form-panel .loading-overlay .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(37, 40, 42, 0.9);
        z-index: 2; }
  .app .registered-message-panel h2 {
    font-size: 2.5rem;
    text-transform: uppercase;
    margin: 0; }

