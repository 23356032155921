// Colors
$planemover_red: #76232f;
$planemover_black: #25282a;
$planemover_teal: #009490;
$planemover_gray: #b2b5b6;
$planemover_white: #fff;

// Variables
$linkTransition: all 250ms ease-in-out;

// Fonts
$planemover_barlow: "Barlow Condensed", sans-serif;
@import url("https://fonts.googleapis.com/css?family=Barlow+Condensed:300,400,500,600&display=swap");

html,
body {
	background-color: $planemover_black;
	min-height: 100vh;
	font-size: 18px;
}

h1 {
	//font-size: 77px;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 2rem;
	margin: 0;
	@media all and (min-width: 991px) {
		font-size: 2.5rem;
	}
}
h2 {
	margin: 0;
	font-size: 1.25rem;
	font-weight: 300;
}
p {
	margin: 0;
}

a {
	color: $planemover_teal;
	transition: $linkTransition;
	text-decoration: none;

	&:hover,
	&:focus {
		color: $planemover_red;
		transition: $linkTransition;
		text-decoration: none;
	}
}

svg,
.svg {
	transition: $linkTransition;
	width: 32px;
	height: 32px;
	fill: $planemover_white;
}

.app {
	min-height: 100vh;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	font-family: $planemover_barlow;
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.1;
	color: $planemover_white;

	* {
		outline: 0 !important;
		box-shadow: none !important;
		text-shadow: none !important;
	}

	.app-header {
		padding-top: 10vh;
		width: 100%;

		#app-header-image-row {
			margin-bottom: 1vh;

			#app-header-image {
				padding-right: 15vw;
			}
		}
		#scroll-down-svg {
			max-width: 40px;
			max-height: 40px;
		}
	}
	.app-main {
		width: 100%;
		padding: 5vh 0;
		background-color: $planemover_red;
		margin: 5vh 0;

		.features {
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				padding: 5px 0;
				margin: 0;
			}
		}
	}
	.app-footer {
		width: 100%;
		padding-bottom: 10vh;

		.section-title {
			margin-bottom: 30px;
		}

		.ctct-inline-form {
			.ctct-form-defaults {
				background-color: transparent;
				padding: 0 0 30px 0;

				.ctct-form-custom {
					.ctct-form-error,
					.ctct-form-errorMessage {
						background-color: #ffDDDD;
						color: $planemover_red;
						padding: 10px;
						margin: 10px 0;
						text-align: center;

						& P {
							margin: 0;
							text-align: center;
						}
					}
					.ctct-form-header {
						margin: 0;
						font-size: 1.5rem;
						font-weight: 300;
						color: $planemover_white;
						font-family: $planemover_barlow;
						line-height: 1.1;
						margin-bottom: 15px;
					}

					.ctct-form-field {
						margin: 0;

						.ctct-form-label {
							//display: none;
							color: $planemover_gray;
							font-family: $planemover_barlow;
							margin: 0 0 5px 0;
							text-transform: uppercase;
							font-size: 1.25rem;
							font-weight: 300;

							&:before {
								color: $planemover_red;
							}
						}

						.ctct-form-element {
							border-radius: 0;
							font-weight: 300;
							font-size: 1.1rem;
							color: $planemover_black;
							font-family: $planemover_barlow;
							text-align: center;
							margin-bottom: 10px;
							border: 0;
						}
					}

					.ctct-gdpr-text {
						color: $planemover_gray;

						A {
							color: $planemover_teal;

							&:hover, &:focus {
								color: $planemover_white;
							}
						}
					}
					.ctct-form-button {
						border-radius: 3px;
						font-family: $planemover_barlow;
						display: inline-block;
						width: auto;
						border: 0;
						border-radius: 3px;
						font-size: 1.2rem;
						padding: 7px 50px;
						text-transform: uppercase;
						font-weight: 500;
						background-color: $planemover_teal;

						transition: $linkTransition;

						&:hover,
						&:focus {
							background-color: $planemover_red;
							transition: $linkTransition;
						}
					}

					
				}
				.ctct-form-footer {
					padding: 0;
				}

				.ctct-form-success {
					.ctct-form-header {
						margin: 0;
						font-size: 2rem;
						font-weight: 300;
						color: $planemover_white;
						font-family: $planemover_barlow;
						line-height: 1.1;
						margin-bottom: 15px;
					}
					.ctct-form-text {
						color: $planemover_white;
						font-family: $planemover_barlow;
					}
				}
			}
		}

		#signup-form {
			max-width: 500px;
			margin: auto;
			margin-bottom: 20px;
			display: block;

			.form-group {
				margin: 0;
			}

			button {
				display: block;
				width: 100%;
				border: 0;
				border-radius: 3px;
				font-size: 1.2rem;
				padding: 3px 20px;
				text-transform: uppercase;
				font-weight: 500;
				background-color: $planemover_teal;

				transition: $linkTransition;

				&:hover,
				&:focus {
					background-color: $planemover_red;
					transition: $linkTransition;
				}
			}
		}
	}

	.signup-form-panel {
		position: relative;

		.loading-overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;

			.spinner {
				position: absolute;
				width: 100%;
				height: 100%;
				z-index: 3;
				display: flex;
				justify-content: center;
				align-items: center;
			}
			.overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba($planemover_black, 0.9);
				z-index: 2;
			}
		}
	}

	.registered-message-panel {
		h2 {
			font-size: 2.5rem;
			text-transform: uppercase;
			margin: 0;
		}
	}
}
